export default [
	{
		path: '/dashboard',
		name: 'dashboard',
		component: () => import('@/views/dashboard/analytics/Analytics.vue'),
	},
	// *===============================================---*
	// *--------- PRODUK ---- ---------------------------------------*
	// *===============================================---*
	// *===============================================---*
	// *===============================================---*
	{
		path: '/apps/produk/list',
		name: 'apps-produk-list',
		component: () => import('@/views/apps/produk/List.vue'),
	},
	{
		path: '/apps/produk/view/:id',
		name: 'apps-produk-view',
		component: () => import('@/views/apps/produk/View.vue'),
	},
	{
		path: '/apps/produk/edit/:id',
		name: 'apps-produk-edit',
		component: () => import('@/views/apps/produk/Edit.vue'),
	},
	// *===============================================---*
	// *--------- CUST ---- ---------------------------------------*
	// *===============================================---*
	// *===============================================---*
	// *===============================================---*
	{
		path: '/apps/customers/list',
		name: 'apps-customers-list',
		component: () => import('@/views/apps/customers/customers-list/CustomersList.vue'),
	},
	// *--------- USER ---- ---------------------------------------*
	// *===============================================---*
	{
		path: '/apps/customers/view/:id',
		name: 'apps-customers-view',
		component: () => import('@/views/apps/customers/customers-view/CustomersView.vue'),
	},
	{
		path: '/apps/customers/edit/:id',
		name: 'apps-customers-edit',
		component: () => import('@/views/apps/customers/customers-edit/CustomersEdit.vue'),
	},

	// Invoice
	// {
	// 	path: '/apps/invoice/list',
	// 	name: 'apps-invoice-list',
	// 	component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
	// },
	// {
	// 	path: '/apps/invoice/bankconfirm/list',
	// 	name: 'apps-invoice-bankconfirm-list',
	// 	component: () =>
	// 		import('@/views/apps/invoice/invoice-list-bankconfirm/InvoiceList.vue'),
	// },
	// {
	// 	path: '/apps/invoice/preview/:id',
	// 	name: 'apps-invoice-preview',
	// 	component: () =>
	// 		import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
	// },
	// {
	// 	path: '/apps/invoice/add/',
	// 	name: 'apps-invoice-add',
	// 	component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
	// },
	// {
	// 	path: '/apps/invoice/edit/:id',
	// 	name: 'apps-invoice-edit',
	// 	component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
	// },
	{
		path: '/apps/discount',
		name: 'apps-discount',
		component: () => import('@/views/apps/discount/Index.vue'),
	},
	{
		path: '/apps/discount/create',
		name: 'apps-create-discount',
		component: () => import('@/views/apps/discount/Create.vue'),
	},
	{
		path: '/apps/discount/:id',
		name: 'apps-show-discount',
		component: () => import('@/views/apps/discount/Create.vue'),
	},
]
