export default [
	{
		path: '/content-management/pages',
		name: 'content-management-page',
		component: () => import('@/views/content-management/page/List.vue'),
	},
	{
		path: '/content-management/components/:id/:component_type',
		name: 'content-management-create-content',
		component: () =>
			import('@/views/content-management/page/EditorComponent.vue'),
	},
	{
		path: '/content-management/pages/:page_id/components',
		name: 'content-management-compenents',
		component: () =>
			import('@/views/content-management/page/component-list/ComponentList.vue'),
	},
]
