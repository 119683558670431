export default [
  {
    path: '/system/users',
    name: 'apps-calendar',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/system/kategoriproduk',
    name: 'master-kategoriproduk',
    component: () => import('@/views/master/kategoriproduk/List.vue'),
  },
  {
    path: '/system/kategoriproduk/:id',
    name: 'master-kategoriproduk-view',
    component: () => import('@/views/master/kategoriproduk/View.vue'),
  },
  {
    path: '/system/flashsale',
    name: 'master-flashsale',
    component: () => import('@/views/master/flashsale/List.vue'),
  },
  {
    path: '/apps/flashsale/create',
    name: 'master-flashsale-create',
    component: () => import('@/views/master/flashsale/View.vue'),
  },
  {
    path: '/apps/flashsale/:id',
    name: 'master-flashsale-view',
    component: () => import('@/views/master/flashsale/View.vue'),
  },  
  {
    path: '/system/review',
    name: 'master-review',
    component: () => import('@/views/master/review/List.vue'),
  },

  {
    path: '/system/levelmember',
    name: 'master-levelmember',
    component: () => import('@/views/master/level_member/List.vue'),
  },
];
