import Vue from 'vue'
import { BModal } from 'bootstrap-vue'
// axios
import axios from 'axios'
import router from '../router'
import config from '@/config/config'

const axiosIns = axios.create({
	// You can add your headers here
	// ================================
	// baseURL: "http://localhost:8281",
	baseURL:
		config.apiUrl,
	// timeout: 1000,
	// headers: {'X-Custom-Header': 'foobar'}
})
// Step-2: Create request, response & error handlers
const requestHandler = request =>
	// Token will be dynamic so we can use any app-specific way to always
	// fetch the new token before making the call
	// request.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIwMTIzNDU2Nzg5IiwibmFtZSI6IlNhbXBsZSIsImlhdCI6MTUxNjIzODIzfQ.ZEBwz4pWYGqgFJc6DIi7HdTN0z5Pfs4Lcv4ZNwMr1rs';
	request

const responseHandler = response => {
	if (response.status === 401) {
		alert(401)
	}

	return response
}

const errorHandler = error => Promise.reject(error)

axiosIns.interceptors.request.use(
	request => requestHandler(request),
	error => errorHandler(error),
)

axiosIns.interceptors.response.use(
	response => responseHandler(response),
	error => {
		const { response } = error
		if ([401, 419].indexOf(error.response.status) > -1) {
			// Reload or redirect to login page
			window.location.reload()
			return Promise.reject(error)
		}

		const errorMsg = response.data.message || response.statusText
		const vm = new Vue()

		if (error.response.status === 429 || error.response.status >= 500) {
			vm.$bvModal
				.msgBoxOk(errorMsg, {
					title: `${error.response.status} ${error.response.statusText}`,
					centered: true,
					okTitle: 'Reload',
					headerBgVariant: 'danger',
					noCloseOnBackdrop: true,
					noCloseOnEsc: true,
				})
				.then(value => {
					router.go()
				})
		}
		if (error.response.status == 405) {
			vm.$bvToast.toast(errorMsg, {
				title: `${error.response.status} ${error.response.statusText}`,
				variant: 'danger',
				solid: true,
				bodyClass: 'danger',
			})
		}

		return Promise.reject(error)
	},
)
Vue.prototype.$http = axiosIns

export default axiosIns
