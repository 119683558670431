import mock from './mock'

/* eslint-disable import/extensions */

// JWT
import './jwt'

// dashboard
import './data/dashboard/analytics'

// pages
import './data/pages/faq-data'
import './data/pages/knowledge-base'
import './data/pages/account-setting'
import './data/pages/profile-data'
/* eslint-enable import/extensions */

mock.onAny().passThrough() // forwards the matched request over network
