export default [
  /*
      {
        path: '/apps/calendar',
        name: 'apps-calendar',
        component: () => import('@/views/apps/calendar/Calendar.vue'),
      },

      // *===============================================---*
      // *--------- EMAIL & IT'S FILTERS N LABELS -------------------------------*
      // *===============================================---*
      {
        path: '/apps/email',
        name: 'apps-email',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'email-application',
        },
      },
      {
        path: '/apps/email/:folder',
        name: 'apps-email-folder',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'email-application',
          navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
          if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
          else next({ name: 'error-404' })
        },
      },
      {
        path: '/apps/email/label/:label',
        name: 'apps-email-label',
        component: () => import('@/views/apps/email/Email.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'email-application',
          navActiveLink: 'apps-email',
        },
        beforeEnter(to, _, next) {
          if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
          else next({ name: 'error-404' })
        },
      },

      // *===============================================---*
      // *--------- TODO & IT'S FILTERS N TAGS ---------------------------------------*
      // *===============================================---*
      {
        path: '/apps/todo',
        name: 'apps-todo',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
        },
      },
      {
        path: '/apps/todo/:filter',
        name: 'apps-todo-filter',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
          if (['important', 'completed', 'deleted'].includes(to.params.filter)) next()
          else next({ name: 'error-404' })
        },
      },
      {
        path: '/apps/todo/tag/:tag',
        name: 'apps-todo-tag',
        component: () => import('@/views/apps/todo/Todo.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'todo-application',
          navActiveLink: 'apps-todo',
        },
        beforeEnter(to, _, next) {
          if (['team', 'low', 'medium', 'high', 'update'].includes(to.params.tag)) next()
          else next({ name: 'error-404' })
        },
      },

      // *===============================================---*
      // *--------- CHAT  ---------------------------------------*
      // *===============================================---*
      {
        path: '/apps/chat',
        name: 'apps-chat',
        component: () => import('@/views/apps/chat/Chat.vue'),
        meta: {
          contentRenderer: 'sidebar-left',
          contentClass: 'chat-application',
        },
      },
    */

  // *===============================================---*
  // *--------- USER ---- ---------------------------------------*
  // *===============================================---*
  {
    path: '/apps/users/list',
    name: 'apps-users-list',
    component: () => import('@/views/apps/user/users-list/UsersList.vue'),
  },
  {
    path: '/apps/users/view/:id',
    name: 'apps-users-view',
    component: () => import('@/views/apps/user/users-view/UsersView.vue'),
  },
  {
    path: '/apps/users/edit/:id',
    name: 'apps-users-edit',
    component: () => import('@/views/apps/user/users-edit/UsersEdit.vue'),
  },

  // Penerimaan
  {
    path: '/apps/penerimaan/list',
    name: 'apps-penerimaan-list',
    component: () => import('@/views/apps/penerimaan/Index.vue'),
  },
  {
    path: '/apps/penerimaan/:id',
    name: 'apps-penerimaan-view',
    component: () => import('@/views/apps/penerimaan/Show.vue'),
  },
  // returPenerimaan
  {
    path: '/apps/returpenerimaan/list',
    name: 'apps-returpenerimaan-list',
    component: () => import('@/views/apps/returpenerimaan/List.vue'),
  },
  {
    path: '/apps/returpenerimaan/:id',
    name: 'apps-returpenerimaan-view',
    component: () => import('@/views/apps/returpenerimaan/View.vue'),
  },
  // Invoice
  // {
  //   path: '/apps/invoice/list',
  //   name: 'apps-invoice-list',
  //   component: () => import('@/views/apps/invoice/invoice-list/InvoiceList.vue'),
  // },
  // {
  //   path: '/apps/invoice/preview/:id',
  //   name: 'apps-invoice-preview',
  //   component: () => import('@/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
  // },
  // {
  //   path: '/apps/invoice/add/',
  //   name: 'apps-invoice-add',
  //   component: () => import('@/views/apps/invoice/invoice-add/InvoiceAdd.vue'),
  // },
  // {
  //   path: '/apps/invoice/edit/:id',
  //   name: 'apps-invoice-edit',
  //   component: () => import('@/views/apps/invoice/invoice-edit/InvoiceEdit.vue'),
  // },
]
