export default [
  {
    path: '/refunds',
    name: 'refunds',
    component: () => import('@/views/apps/refund/Index.vue'),
  },
  {
    path: '/refunds/:id',
    name: 'refunds_detail',
    component: () => import('@/views/apps/refund/Show.vue'),
  },
]
