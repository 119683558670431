import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import Toast from 'vue-toastification'

import _ from 'lodash'
import VueCryptojs from 'vue-cryptojs/src/index.mjs'
import Ckeditor from '@ckeditor/ckeditor5-vue2'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// DevExtreme Style
import 'devextreme/dist/css/dx.light.css'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(VueCryptojs)
Vue.use(_)
// Composition API
Vue.use(VueCompositionAPI)
Vue.use(Ckeditor)
Vue.use(Toast)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')
