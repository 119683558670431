export default [
	{
		path: '/return-sales',
		name: 'return_sales',
		component: () => import('@/views/apps/return_sale/Index.vue'),
	},
	{
		path: '/return-sales/:id',
		name: 'return_sales_detail',
		component: () => import('@/views/apps/return_sale/Show.vue'),
		meta: {
			navActiveLink: 'return-sales',
		},
	},
]
