export default [
  {
    path: '/faqs',
    name: 'faq',
    component: () => import('@/views/faq/Index.vue'),
  },
  {
    path: '/faqs/:id',
    name: 'faq-show',
    component: () => import('@/views/faq/Show.vue'),
  },
]
