export default [
  {
    path: '/report/stock',
    name: 'stock',
    component: () => import('@/views/report/stock/Index.vue'),
  },
  {
    path: '/report/transaction',
    name: 'transaction-report',
    component: () => import('@/views/report/transaction/Index.vue'),
  },
]
