export default [
  {
    path: '/payment-step',
    name: 'payment-step',
    component: () => import('@/views/payment/payment-step/Index.vue'),
  },
  {
    path: '/payment-step/create',
    name: 'payment-step-create',
    component: () => import('@/views/payment/payment-step/Create.vue'),
  },
  {
    path: '/payment-step/:id',
    name: 'payment-step-edit',
    component: () => import('@/views/payment/payment-step/Create.vue'),
  },
]
