export default [
  {
    path: '/tags',
    name: 'tags',
    component: () => import('@/views/tags/Index.vue'),
  },
  {
    path: '/tags/:id',
    name: 'tags_detail',
    component: () => import('@/views/tags/Show.vue'),
  },
]