export default [
	{
		path: '/order',
		name: 'order',
		component: () => import('@/views/apps/order/Index.vue'),
	},
	{
		path: '/order/:id',
		name: 'order-detail',
		component: () => import('@/views/apps/order/Show.vue'),
		meta: {
			navActiveLink: 'order',
		},
	},
]
