const apiUrl = process.env.NODE_ENV === 'development'
    ? 'http://adm-commerce.test'
    : 'https://api.admin-ecommerce.ingco.co.id'

const googleService = {
    mapApiKey: process.env[`VUE_APP_GOOGLE_MAPS_KEY_${process.env.NODE_ENV.toUpperCase()}`],
}

export default {
    apiUrl,
    googleService,
}
